import React from 'react'
import tw from 'twin.macro'
import SectionStyle from '../atoms/util/SectionStyle'
import H3Style from '../atoms/typo/H3Style'
import Wrapper from '../atoms/util/Wrapper'
import ParagraphStyle from '../atoms/typo/ParagraphStyle'

const Contact: React.FC = () => {
  return (
    <section css={SectionStyle} id="contact">
      <h3 css={H3Style(tw`text-center`)}>お問い合わせ</h3>
      <Wrapper>
        <p css={ParagraphStyle(tw`text-center mt-6`)}>
          右下のボタンを押してから、
          <br />
          問い合わせをしたいエリアをお選びください。
        </p>
      </Wrapper>
    </section>
  )
}

export default Contact
