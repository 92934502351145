import React from 'react'
import { PageProps } from 'gatsby'
import Layout from '@/shared/Layout'
import SEO from '@/shared/seo'
import IndexPageLayout from '@/components/templates/IndexPageLayout'

const Home: React.FC<PageProps> = () => (
  <Layout>
    <SEO
      title="LocalPlan"
      description="地域のお店と人を繋ぐプラットフォーム。"
    />
    <IndexPageLayout />
  </Layout>
)

export default Home
