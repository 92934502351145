import React, { useRef } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import tw, { css, TwStyle } from 'twin.macro'
import SectionStyle from '../atoms/util/SectionStyle'
import H3Style from '../atoms/typo/H3Style'
import ParagraphStyle from '../atoms/typo/ParagraphStyle'
import Wrapper from '../atoms/util/Wrapper'
import Container from '../atoms/flex/Container'
import isInView from '@/shared/isInView'

const ArticleStyle = (isView?: boolean, overWrite?: TwStyle) =>
  css(
    tw`mx-4 bg-white rounded-lg md:w-1/3 w-11/12 text-center lg:mt-20 mt-10 shadow-md p-5 relative duration-700`,
    isView ? tw`opacity-100 top-0` : tw`opacity-0 top-10`,
    overWrite && overWrite,
  )

const ImageContainerStyle = css(
  tw`w-14 absolute -left-5 -top-5 bg-white shadow-md rounded-full p-3`,
)

const Introduction: React.FC = () => {
  const targetRef = useRef(null)
  const targetViewPos = isInView(targetRef)
  return (
    <section css={SectionStyle()}>
      <Wrapper>
        <h3 css={H3Style(tw`text-center`)}>こんな悩みありませんか？</h3>
        <Container>
          <article css={ArticleStyle(targetViewPos)} ref={targetRef}>
            <div css={ImageContainerStyle}>
              <StaticImage
                src="../../../static/images/bad.png"
                alt="code"
                placeholder="blurred"
              />
            </div>
            <h3 css={H3Style(tw`text-gray-700 mt-2`)}>情報発信ができない</h3>
            <p css={ParagraphStyle(tw`text-gray-700 mt-2`)}>
              ITについてよくわからない
              <br />
              とりあえずSNSアカウントを作ったけどそのままに...
              <br />
              サイトも高くて作れない
            </p>
          </article>
          <article css={ArticleStyle(targetViewPos, tw`delay-200`)}>
            <div css={ImageContainerStyle}>
              <StaticImage
                src="../../../static/images/bad.png"
                alt="code"
                placeholder="blurred"
              />
            </div>
            <h3 css={H3Style(tw`text-gray-700 mt-2`)}>知ってもらえない</h3>
            <p css={ParagraphStyle(tw`text-gray-700 mt-2`)}>
              お店の立地が悪い
              <br />
              新しいことをしても、
              <br />
              知ってもらう機会がない
              <br />
              集客方法がわからない
            </p>
          </article>
          <article css={ArticleStyle(targetViewPos, tw`delay-300`)}>
            <div css={ImageContainerStyle}>
              <StaticImage
                src="../../../static/images/bad.png"
                alt="code"
                placeholder="blurred"
              />
            </div>
            <h3 css={H3Style(tw`text-gray-700 mt-2 `)}>常連が固定化</h3>
            <p css={ParagraphStyle(tw`text-gray-700 mt-2`)}>
              いつも来てくれる人が同じに
              <br />
              発信がうまくできなくて、
              <br />
              新規のお客さんは体力のある企業へ流れてしまう
            </p>
          </article>
        </Container>
      </Wrapper>
    </section>
  )
}

export default Introduction
