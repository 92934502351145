import React from 'react'
import 'twin.macro'
import Introduction from '../organisms/Introduction'
import MainVisual from '../organisms/MainVisual'
import About from '../organisms/About'
import Services from '../organisms/Services'
import Merit from '../organisms/Merit'
import Voice from '../organisms/Voice'
import Flow from '../organisms/Flow'
import Example from '../organisms/Example'
import Contact from '../organisms/Contact'
import Conversion from '../organisms/Conversion'

const IndexPageLayout: React.FC = () => {
  return (
    <div tw="bg-gray-100">
      <MainVisual />
      <Introduction />
      <About />
      <Services />
      <Merit />
      <Voice />
      <Flow />
      <Example />
      <Contact />
      <Conversion />
    </div>
  )
}

export default IndexPageLayout
