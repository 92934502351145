import React from 'react'
import tw from 'twin.macro'
import { StaticImage } from 'gatsby-plugin-image'
import SectionStyle from '../atoms/util/SectionStyle'
import H3Style from '../atoms/typo/H3Style'
import Container from '../atoms/flex/Container'
import Wrapper from '../atoms/util/Wrapper'
import ParagraphStyle from '../atoms/typo/ParagraphStyle'

const CardStyle = tw`block bg-white shadow-md rounded-lg md:mx-2 md:my-0 my-2 transform overflow-hidden duration-500 pt-2`
const TextAreaStyle = tw`p-4`

const Flow: React.FC = () => {
  return (
    <section css={SectionStyle()} id="flow">
      <h3 css={H3Style(tw`text-center lg:mb-12 mb-6`)}>掲載までの流れ</h3>
      <Wrapper>
        <Container>
          <div css={CardStyle}>
            <StaticImage src="../../../static/images/flow1.png" alt="flow1" />
            <div css={TextAreaStyle}>
              <p css={ParagraphStyle(tw`text-center`)}>①LINEで友達登録</p>
            </div>
          </div>
          <div css={CardStyle}>
            <StaticImage src="../../../static/images/flow2.png" alt="flow1" />
            <div css={TextAreaStyle}>
              <p css={ParagraphStyle(tw`text-center`)}>②LINEの指示通り送信</p>
            </div>
          </div>
          <div css={CardStyle}>
            <StaticImage src="../../../static/images/flow3.png" alt="flow1" />
            <div css={TextAreaStyle}>
              <p css={ParagraphStyle(tw`text-center`)}>
                ③作成されたページを確認
              </p>
            </div>
          </div>
        </Container>
      </Wrapper>
    </section>
  )
}

export default Flow
