import React, { useRef } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import tw, { css, TwStyle } from 'twin.macro'
import SectionStyle from '../atoms/util/SectionStyle'
import H3Style from '../atoms/typo/H3Style'
import ParagraphStyle from '../atoms/typo/ParagraphStyle'
import Wrapper from '../atoms/util/Wrapper'
import Container from '../atoms/flex/Container'
import isInView from '@/shared/isInView'

const ArticleStyle = (isView?: boolean, overWrite?: TwStyle) =>
  css(
    tw`mx-4 bg-white rounded-lg md:w-1/3 w-11/12 text-center lg:mt-20 mt-10 shadow-md p-5 relative duration-700`,
    isView ? tw`opacity-100 top-0` : tw`opacity-0 top-10`,
    overWrite && overWrite,
  )

const ImageContainerStyle = css(
  tw`w-14 absolute -left-5 -top-5 bg-white shadow-md rounded-full p-3`,
)

const Merit: React.FC = () => {
  const targetRef = useRef(null)
  const targetViewPos = isInView(targetRef)
  return (
    <section css={SectionStyle()} id="merit">
      <Wrapper>
        <h3 css={H3Style(tw`text-center`)}>
          <span tw="font-family['Kiwi Maru']">LocalPlan</span>を導入するメリット
        </h3>
        <Container>
          <article css={ArticleStyle(targetViewPos)} ref={targetRef}>
            <div css={ImageContainerStyle}>
              <StaticImage
                src="../../../static/images/good.png"
                alt="code"
                placeholder="blurred"
              />
            </div>
            <h3 css={H3Style(tw`text-gray-700 mt-2`)}>情報発信が簡単に</h3>
            <p css={ParagraphStyle(tw`text-gray-700 mt-2`)}>
              LINEだけで利用できるので、
              <br />
              日常の発信が簡単に！
              <br />
              友達に連絡する感じで、
              <br />
              簡単に情報発信ができます！
            </p>
          </article>
          <article css={ArticleStyle(targetViewPos, tw`delay-200`)}>
            <div css={ImageContainerStyle}>
              <StaticImage
                src="../../../static/images/good.png"
                alt="code"
                placeholder="blurred"
              />
            </div>
            <h3 css={H3Style(tw`text-gray-700 mt-2`)}>無料で安心</h3>
            <p css={ParagraphStyle(tw`text-gray-700 mt-2`)}>
              今まで紹介した機能が
              <br />
              無料で利用できます！
              <br />
              登録料や入会金もないので
              <br />
              安心してご利用下さい！
            </p>
          </article>
          <article css={ArticleStyle(targetViewPos, tw`delay-300`)}>
            <div css={ImageContainerStyle}>
              <StaticImage
                src="../../../static/images/good.png"
                alt="code"
                placeholder="blurred"
              />
            </div>
            <h3 css={H3Style(tw`text-gray-700 mt-2 `)}>地元で愛される店に</h3>
            <p css={ParagraphStyle(tw`text-gray-700 mt-2`)}>
              地元の人が見るサイトなので
              <br />
              いい取り組みを続けていれば
              <br />
              新規率も向上し、
              <br />
              常連さんが増える可能性UP!
            </p>
          </article>
        </Container>
      </Wrapper>
    </section>
  )
}

export default Merit
