import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import tw from 'twin.macro'
import SectionStyle from '../atoms/util/SectionStyle'
import H3Style from '../atoms/typo/H3Style'
import ParagraphStyle from '../atoms/typo/ParagraphStyle'
import Wrapper from '../atoms/util/Wrapper'
import Container from '../atoms/flex/Container'

const ImageStyle = tw`md:mx-2 mx-0 md:my-0 my-2`

const Services: React.FC = () => {
  return (
    <section css={SectionStyle} id="services">
      <Wrapper>
        <h3 css={H3Style(tw`text-center mb-12`)}>
          <span tw="font-family['Kiwi Maru']">LocalPlan</span>の特徴
        </h3>
        <Container>
          <div tw="md:block flex flex-col-reverse">
            <StaticImage
              src="../../../static/images/news.png"
              alt=""
              css={ImageStyle}
            />
            <p css={H3Style(tw`text-center lg:my-8 my-4 font-medium`)}>
              ニュースの投稿ができる
            </p>
          </div>
          <div tw="md:block flex flex-col-reverse">
            <StaticImage
              src="../../../static/images/shops.png"
              alt=""
              css={ImageStyle}
            />
            <p css={H3Style(tw`text-center lg:my-8 my-6 font-medium`)}>
              いろんなお店が探せる
            </p>
          </div>
          <div tw="md:block flex flex-col-reverse">
            <StaticImage
              src="../../../static/images/search.png"
              alt=""
              css={ImageStyle}
            />
            <p css={H3Style(tw`text-center lg:my-8 my-6 font-medium`)}>
              画像で検索もできる
            </p>
          </div>
        </Container>
        <div tw="bg-white text-center p-6 mt-6">
          <h4 css={H3Style(tw`mt-2`)}>
            LINEをするだけで簡単にwebページが作れます！
          </h4>
          <h4 css={H3Style(tw`mt-2`)}>
            1日1回ニュースの投稿、お店の情報の変更ができます！
          </h4>
          <h4 css={H3Style(tw`mt-2`)}>入会金、月額費用0円！</h4>
        </div>
      </Wrapper>
    </section>
  )
}

export default Services
