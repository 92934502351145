import React from 'react'
import tw from 'twin.macro'
import { StaticImage } from 'gatsby-plugin-image'
import SectionStyle from '../atoms/util/SectionStyle'
import H3Style from '../atoms/typo/H3Style'
import Wrapper from '../atoms/util/Wrapper'
import ParagraphStyle from '../atoms/typo/ParagraphStyle'
import Container from '../atoms/flex/Container'

const About: React.FC = () => {
  return (
    <section css={SectionStyle()} id="about">
      <Wrapper>
        <h3 css={H3Style(tw`text-center`)}>
          <span tw="font-family['Kiwi Maru'] tracking-widest">LocalPlan</span>
          とは？
        </h3>
        <Container>
          <StaticImage
            src="../../../static/images/phone1.png"
            alt="サービスイメージ"
            placeholder="blurred"
            tw="lg:w-1/2"
          />
          <p css={ParagraphStyle(tw`width[fit-content] lg:leading-relaxed`)}>
            地元のお店の活性を目的とした、
            <br />
            地元密着型の検索・情報サイト。
            <br />
            対象エリアを絞って、
            <br />
            各エリアに特化したサイトとして運営。
            <br />
            エリア内の飲食・医療・美容・雑貨店等の
            <br />
            ジャンルを問わず取扱うことで地元民が日常的に
            <br />
            使いやすいサービスを目指します。
          </p>
        </Container>
      </Wrapper>
    </section>
  )
}

export default About
