import React, { useState } from 'react'
import tw from 'twin.macro'

const OpenStyle = tw`fixed w-screen h-screen top-0 left-0 bg-green-600 bg-opacity-90 z-50 grid place-items-center px-4 duration-200`
const CloseStyle = tw`fixed w-screen h-screen top-full overflow-hidden duration-500`
const LinkStyle = tw`block text-white mt-4 tracking-widest text-lg hover:text-gray-200 underline`

const Conversion: React.FC = () => {
  const [isOpen, toggle] = useState(false)
  return (
    <>
      <div
        onClick={() => toggle(!isOpen)}
        tw="bg-green-500 text-white text-lg fixed md:right-12 md:bottom-12 bottom-2 right-2 md:w-32 md:h-32  md:p-0 p-3 rounded-full flex items-center justify-center z-40 hover:bg-green-700 duration-500 shadow-lg md:animate-bounce md:animation-duration[2s] cursor-pointer"
      >
        友達登録
      </div>
      <div
        css={isOpen ? OpenStyle : CloseStyle}
        onClick={() => toggle(!isOpen)}
      >
        <nav tw="grid-area[1/1]">
          <p tw="text-white">
            お問い合わせは各エリアのLINE公式アカウントより受け付けております。
          </p>
          <a css={LinkStyle} href="https://lin.ee/qqA0asL" target="_blank">
            TamaPlan（たまプラーザエリア）
          </a>
          <a css={LinkStyle} href="https://lin.ee/eqRAbIb" target="_blank">
            Oyafukou Plan（親不孝通りエリア）
          </a>
        </nav>
      </div>
    </>
  )
}

export default Conversion
