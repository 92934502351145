import React from 'react'
import tw from 'twin.macro'
import { StaticImage } from 'gatsby-plugin-image'
import SectionStyle from '../atoms/util/SectionStyle'
import H3Style from '../atoms/typo/H3Style'
import Wrapper from '../atoms/util/Wrapper'
import Container from '../atoms/flex/Container'
import ParagraphStyle from '../atoms/typo/ParagraphStyle'

const CardStyle = tw`bg-white mx-3 p-4 max-w-xs shadow-lg rounded-lg relative lg:mt-0 mt-4`
const TextAreaStyle = tw`mt-4 text-center`
const CommingSoon = tw`absolute top-0 left-0 bg-black text-white w-full h-full bg-opacity-60 flex justify-center items-center text-lg`

const Example: React.FC = () => {
  return (
    <section css={SectionStyle} id="example">
      <h3 css={H3Style(tw`text-center lg:mb-12 mb-2`)}>サービス実施エリア</h3>
      <Wrapper>
        <Container>
          <a
            href="https://tamaplaza.localplan.app"
            target="_blank"
            css={CardStyle}
          >
            <StaticImage
              src="../../../static/images/tamaplan.png"
              alt="TamaPlan"
            />
            <p css={ParagraphStyle(TextAreaStyle)}>
              たまプラーザ駅付近（神奈川県）
            </p>
          </a>
          <div css={CardStyle}>
            <StaticImage
              src="../../../static/images/oyafukou.png"
              alt="Oyafukou"
            />
            <p css={ParagraphStyle(TextAreaStyle)}>親不孝通り付近（福岡県）</p>
            <div css={CommingSoon}>Comming Soon</div>
          </div>
        </Container>
      </Wrapper>
    </section>
  )
}

export default Example
