import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import tw from 'twin.macro'
import SectionStyle from '../atoms/util/SectionStyle'

const MainVisual: React.FC = () => {
  return (
    <section css={SectionStyle(tw`grid lg:mt-0`)}>
      <StaticImage
        css={tw`grid-area[1/1] max-height[700px]`}
        src="../../../static/images/main.jpg"
        alt="背景"
        layout="fullWidth"
        breakpoints={[]}
        formats={['auto', 'webp', 'avif']}
        placeholder="blurred"
      />
      <div tw="grid-area[1/1] relative grid place-items-center bg-black bg-opacity-30 z-20">
        <h2 tw="text-white lg:text-5xl md:text-3xl text-2xl lg:leading-snug md:leading-snug leading-snug tracking-widest">
          住んでて楽しい地元に
          <br />
          やってて楽しいお店に
          <br />
          <span tw="font-family['Kiwi Maru']">LocalPlan</span>で
          <br />
          そんな地元にしませんか？
        </h2>
      </div>
    </section>
  )
}

export default MainVisual
