import React from 'react'
import tw, { css } from 'twin.macro'
import SectionStyle from '@/components/atoms/util/SectionStyle'
import SlideShow from '@/components/molecules/SlideShow'
import H3Style from '@/components/atoms/typo/H3Style'
import ParagraphStyle from '@/components/atoms/typo/ParagraphStyle'

const CardStyle = tw`block bg-white shadow-md rounded-lg md:mx-12 mx-8 transform overflow-hidden duration-500 hover:rotate-0`
const TextAreaStyle = tw`p-4`

const Voice: React.FC = () => {
  return (
    <section css={SectionStyle(tw``)} id="voice">
      <h3 css={H3Style(tw`text-center`)}>お客様の声</h3>
      <div tw="lg:mt-16 mt-8 w-full">
        <SlideShow>
          <div css={css(CardStyle, tw`rotate-6 md:w-96 w-72`)}>
            <div css={css(TextAreaStyle)}>
              <p css={ParagraphStyle(tw`text-gray-700 mt-2`)}>
                LINEで掲載できるので、
                <br /> 機械音痴の自分でも簡単に利用できました。
              </p>
            </div>
          </div>
          <div css={css(CardStyle, tw`md:w-80 w-60 -rotate-3 px-4`)}>
            <div css={css(TextAreaStyle, tw`px-0`)}>
              <p css={ParagraphStyle(tw`text-gray-700 mt-2`)}>
                LINE公式アカウントから、 応援メッセージが来て、
                情報発信を忘れずに続けられて励みになります✨
              </p>
            </div>
          </div>
          <div css={css(CardStyle, tw`md:w-64 w-48 rotate-12 px-4`)}>
            <div css={css(TextAreaStyle, tw`px-0`)}>
              <p css={ParagraphStyle(tw`text-gray-700 mt-2`)}>
                無料で利用できるので、
                <br />
                とりあえず初めてみました！
                <br />
                今後に期待しています💪
              </p>
            </div>
          </div>
          <div css={css(CardStyle, tw`-rotate-3 md:w-80 w-60 px-4`)}>
            <div css={css(TextAreaStyle, tw`px-0`)}>
              <p css={ParagraphStyle(tw`text-gray-700 mt-2`)}>
                チャットで情報を送っただけで、
                <br />
                綺麗なwebページが作られていて
                <br />
                感心しました🙆‍♂️
              </p>
            </div>
          </div>
          <div css={css(CardStyle, tw`rotate-6 md:w-80 w-60`)}>
            <div css={css(TextAreaStyle)}>
              <p css={ParagraphStyle(tw`text-gray-700 mt-2`)}>
                ログインなど面倒な手間がなく、
                <br />
                常連さんに告知する感じで、
                <br />
                LINEを送るだけなので、
                <br />
                便利で良いです🌟
              </p>
            </div>
          </div>
          <div css={css(CardStyle, tw`-rotate-3 md:w-80 w-60`)}>
            <div css={css(TextAreaStyle)}>
              <p css={ParagraphStyle(tw`text-gray-700 mt-2`)}>
                地元のために頑張っていて、
                <br />
                感心します。
                <br />
                応援も込めて始めてみました🙌
              </p>
            </div>
          </div>
        </SlideShow>
      </div>
    </section>
  )
}

export default Voice
