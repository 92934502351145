import React from 'react'
import tw, { css } from 'twin.macro'
import { mediaQuery } from '@/shared/cssValue'

const style = css(
  mediaQuery({
    maxWidth: ['100%', '100%', '100%', '992px'],
  }),
  tw`lg:mx-auto mx-5`,
)

const Wrapper: React.FC = ({ children }) => {
  return <div css={style}>{children}</div>
}

export default Wrapper
